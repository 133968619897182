import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Thanks from './components/Home/thanks';


function App() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
  <>
  <Router>
      <div className="min-vh-100 d-flex flex-column">
        <Navbar show={showModal} handleClose={handleCloseModal} handleOpen={handleShowModal}/>
      
        <div className="flex-grow-1">
          <Routes>
            <Route path="/" element={
      <Home handleCloseModal={handleCloseModal} onConsultationClick={handleShowModal} />
              
               } />
            <Route path="/thanks" element={<Thanks />} />
          </Routes>
      
        </div>
      </div>
    </Router>
  </>
  );
}

export default App;
